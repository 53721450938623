import React, { useEffect } from 'react';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import { properCase } from '../../helpers/utils';
import * as d3 from 'd3-scale-chromatic';

export default function GraphSimplePieChart({ data = [] }) {
  const chartdata = data?.filter((x) => x.count > 0);

  // Function to generate random colors
  // const generateColors = (num) => {
  //   return Array.from({ length: num }, () => `#${Math.floor(Math.random() * 16777215).toString(16)}`);
  // };

  const COLORS = d3.schemeCategory10;

  //const COLORS = generateColors(chartdata.length);
  // const COLORS = ['#0C7A2D', '#0088FE', '#D14B19', '#00C49F', '#FFBB28', '#FF8042', '#A2179B'];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <PieChart width={400} height={400} style={{ border: '0px solid gray' }}>
        <Pie
          data={chartdata}
          cx={170}
          cy={200}
          innerRadius={80} // Added innerRadius to create a donut chart
          outerRadius={150}
          labelLine={false}
          label={renderCustomizedLabel}
          fill="#8884d8"
          dataKey="count"
        >
          {chartdata.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
      <div style={{ marginLeft: '0px' }}>
        {data.map((entry, index) => (
          <div
            key={`legend-${index}`}
            style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}
          >
            <div
              style={{
                width: '10px',
                height: '10px',
                backgroundColor: COLORS[index % COLORS.length],
                marginRight: '2px',
              }}
            ></div>
            <span style={{ fontSize: '10px' }}>
              {properCase(entry.name)} - <strong>{entry.count}</strong>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
