import { Col, Form, Input, Modal, Row, Spin, Upload, DatePicker } from 'antd';
import CloseIcon from '../../../common/CloseIcon';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import axios from 'axios';
import { baseURL, getToken } from '../../../../helpers/api';
import Button from '../../../../components/common/Button';
import { openNotification } from '../../../../helpers/notification';

export default function InvoicePaymentApprovalForm({
  showApprovalForm,
  toggleApprovalForm,
  facilityName,
  invoiceId,
}) {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  // Handle form submission
  const handleFormSubmit = async (values) => {
    setLoading(true);
    console.log('Form values:', values); // Debug: Log form values
    try {
      // Create a FormData object to send both input values and file
      const formData = new FormData();
      const formattedDatePaid = values.datePaid.toISOString();
      console.log('formatter date', formattedDatePaid);

      // Append form inputs (text fields)
      formData.append('bankName', values.bankName);
      formData.append('bankAccount', values.bankAccountName);
      formData.append('paymentReference', values.tellerNo);
      formData.append('amountPaid', values.amount);
      formData.append('paymentDescription', values.comment);
      formData.append('invoiceId', invoiceId);
      formData.append('datePaid', formattedDatePaid);

      // Check if fileList has files
      if (fileList.length > 0) {
        const file = fileList[0].originFileObj; // Take the first file from fileList
        //console.log('File to be uploaded:', file); // Debug: Log the file
        formData.append('receipt', file); // Append the file as "receipt"
        console.log('formdata', formData.values('receipt'));
      } else {
        console.log('No file selected.'); // Debug: Log if no file is selected
      }

      //Submit the form data to the API endpoint
      const response = await axios.post(`${baseURL}/Payment/ApprovePayment`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Required for file upload
          Authorization: `bearer ${getToken()}`,
        },
      });

      //const response = await approvePayment(formData)
      console.log('The response', response.data?.result?.status?.code);
      if (response.data?.result?.status?.code === '00') {
        setLoading(false);
        return openNotification({
          //duration: 2000,
          type: 'success',
          title: 'Success',
          message: 'Submitted successfully',
        });
      }

      setTimeout(() => {
        toggleApprovalForm(false);
      }, 1000);

      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      return openNotification({
        //duration: 2000,
        type: 'error',
        title: 'Failed',
        message: 'Failed to submit successfully',
      });
    }
  };

  // Handle file changes
  const handleFileChange = ({ fileList }) => {
    console.log('File list updated:', fileList); // Debug: Log file list changes
    setFileList(fileList); // Update fileList state
  };

  return (
    <Modal
      open={showApprovalForm}
      title={
        <div style={{ display: 'flex' }}>
          <h3 style={{ flexGrow: 1 }}>Approve Payment</h3>
          <CloseIcon className="close-icon" onClick={() => toggleApprovalForm(false)} />
        </div>
      }
      footer={null}
      centered={true}
      className="standard-report-modal"
    >
      <h3>
        <strong>{facilityName}</strong>
      </h3>
      <br />
      <Form
        initialValues={{
          bankName: '',
          bankAccountName: '',
          tellerNo: '',
          amount: 0,
          comment: '',
          datePaid: '',
        }}
        layout="vertical"
        onFinish={handleFormSubmit} // Call onFinish for form submission
      >
        <Row gutter={2}>
          <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Row gutter={10}>
              <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item
                  label="Bank Name"
                  name="bankName"
                  rules={[{ required: true, message: 'Please enter bank name!' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item
                  label="Bank Account Name"
                  name="bankAccountName"
                  rules={[{ required: true, message: 'Please enter bank account name!' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item
                  label="Paid Amount"
                  name="amount"
                  rules={[{ required: true, message: 'Please enter paid amount!' }]}
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                <Form.Item
                  label="Date Paid"
                  name="datePaid"
                  rules={[{ required: true, message: 'Please enter date!' }]}
                >
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 12 }}>
                <Form.Item label="Reference No." name="tellerNo">
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                <Form.Item label="Comment" name="comment">
                  <Input.TextArea rows={5} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Form.Item valuePropName="fileList">
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  onChange={handleFileChange}
                  beforeUpload={() => false} // Prevent default upload behavior
                >
                  {fileList.length < 1 && (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload Receipt</div>
                    </div>
                  )}
                </Upload>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <div style={{ marginTop: 16 }}>
          <Button type="primary" appearance="default" htmlType="submit" disabled={loading}>
            Approve {loading && <Spin indicator={<LoadingOutlined spin />} size="small" />}
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
