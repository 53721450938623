export default function FacilityQuarterlyReport() {
  return (
    <div style={{ border: '0px solid gray', margin: '-30px 0 0 -32px', padding: 0 }}>
      <div style={{ height: '100vh', width: '100%', border: 'none' }}>
        <iframe
          src={`${window.TELERIK_BASE_URL}/facilityreport`}
          style={{ width: '100%', height: '100%', border: 'none' }}
          title="Embedded Webpage"
        />
      </div>
    </div>
  );
}
