import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import NavBrand from './NavBrand';
import SideBarLink from './SideBarLink';
import { ReactComponent as ReportsIcon } from '../../assets/img/reports.svg';
import { ReactComponent as AdminReportIcon } from '../../assets/img/reports_2.svg';
import { ReactComponent as UsersIcon } from '../../assets/img/users.svg';
import { ReactComponent as FacilitiesIcon } from '../../assets/img/facilities.svg';
import { ReactComponent as SettingsIcon } from '../../assets/img/settings.svg';
import { ReactComponent as SchedulerIcon } from '../../assets/img/scheduler.svg';
import { ReactComponent as DashboardIcon } from '../../assets/img/dashboard.svg';
import { AppContext } from '../../store/AppContext';

const xs = window.matchMedia('(max-width: 1px)');
// const xs = window.matchMedia('(max-width: 769px)');

const SideNav = ({ className, isOpen, setOpen }) => {
  const [userPermissions, setuserPermissions] = useState([]);

  const { state } = useContext(AppContext);
  const { userCategory, userPermission } = state.auth.result.data;
  //console.log(userPermission)
  const permissions = userPermission.flatMap((i) => {
    return i.permissionActivity.map((i2) => i2.privilegeCode.toLowerCase());
  });

  const canViewReports =
    permissions.includes('view_report') || userPermissions.includes('view_comparative_report');

  const canViewSchedules = permissions.includes('schedule_quarter');

  const canViewFacility =
    permissions.includes('view_facility') ||
    permissions.includes('create_facility') ||
    permissions.includes('update_facility');

  const canViewUsers = permissions.includes('view_users') || permissions.includes('user_invite');

  const links = [
    {
      Icon: DashboardIcon,
      name: 'Dashboard',
      url: '/',
      visible: true,
    },
    {
      Icon: ReportsIcon,
      name: 'Results',
      url: '/reports',
      visible: canViewReports,
    },
    {
      Icon: SchedulerIcon,
      name: 'QA/QC Scheduler',
      url: '/schedules',
      visible: canViewSchedules,
    },
    {
      Icon: FacilitiesIcon,
      name: 'Facilities',
      url: '/facilities',
      visible: canViewFacility,
    },
    {
      Icon: UsersIcon,
      name: 'Users',
      url: '/users',
      visible: canViewUsers,
    },
    {
      Icon: ReportsIcon,
      name: 'Logs',
      url: '/logs',
      visible: true,
    },
    {
      Icon: ReportsIcon,
      name: 'Reports',
      url: '/reporting',
      visible: true,
    },
    // {
    //   Icon: ReportsIcon,
    //   name: 'Analytics',
    //   url: '/analytics',
    //   visible: true,
    // },
    {
      Icon: SettingsIcon,
      name: 'Settings',
      url: '/settings',
      visible: true,
    },
  ];

  useEffect(() => {
    const authURL = [];
    if (userPermission && userPermission.length > 0) {
      userPermission.map((userPermit) => {
        if (userPermit.permissionActivity.length > 0) {
          userPermit.permissionActivity.map((activity) => {
            authURL.push(activity.privilegeCode);
          });
        }
      });
    }
    setuserPermissions(authURL);
  }, []);

  useEffect(() => {
    // Add Class To Add Effect When Component is Loaded on Mobile Screen
    if (xs.matches) {
      const sideNav = document.querySelector('.SideNav');
      if (isOpen) {
        sideNav.classList.add('SideNav-Expand');
        sideNav.classList.remove('SideNav-Collapse');
      } else {
        sideNav.classList.remove('SideNav-Expand');
        sideNav.classList.add('SideNav-Collapse');
      }
    }
  }, []);

  // console.log('user', userCategory);
  // console.log('user per', userPermissions);
  const isEDM = userCategory && userCategory.toLowerCase().trim() === 'edm';
  const isConsultant = userCategory && userCategory.toLowerCase().trim() === 'consultant';
  const isRegulator = userCategory && userCategory.toLowerCase().trim() === 'regulator';

  // Add Side Menu Toggler on Mobile Screens
  const toggleSideNav = () => {
    // setOpen(!isOpen);
    setOpen();
  };

  // Handles Display of Side Menu Items Based on Screen Size
  const displayNavContent = () => {
    if (xs.matches) {
      if (!isOpen) {
        return links.map(({ Icon, name, url }) => {
          return (
            <SideBarLink key={name} url={url} sideNavExpand={isOpen}>
              <Icon />
            </SideBarLink>
          );
        });
      } else {
        return links.map(({ Icon, name, url }) => {
          return (
            <SideBarLink key={name} url={url} sideNavExpand={isOpen}>
              <Icon />
              {isOpen && <span>{name}</span>}
            </SideBarLink>
          );
        });
      }
    } else {
      return links.map(({ Icon, name, url, visible }) => {
        if (userCategory === 'EDM') {
          if (visible) {
            return (
              <SideBarLink key={name} url={url} title={name}>
                <Icon />
                {isOpen && <span>{name}</span>}
              </SideBarLink>
            );
          }
        } else {
          if (userPermissions === null) {
            return null;
          } else {
            if (url === '/' && !isConsultant) {
              return (
                <SideBarLink key={name} url={url} title={name}>
                  <Icon />
                  {isOpen && <span>{name}</span>}
                </SideBarLink>
              );
            } else if (
              userPermissions.find((e) => e === 'VIEW_REPORT' || e === 'RECORD_REPORT') &&
              url === '/reports'
            ) {
              return (
                <SideBarLink key={name} url={url} title={name}>
                  <Icon />
                  {isOpen && <span>{isConsultant ? `My ${name}` : name}</span>}
                </SideBarLink>
              );
            } else if (
              (userPermissions.find((e) => e === 'VIEW_REPORT' || e === 'RECORD_REPORT') &&
                url === '/logs' &&
                isEDM) ||
              (userPermissions.find((e) => e === 'VIEW_REPORT' || e === 'RECORD_REPORT') &&
                url === '/logs' &&
                isRegulator)
            ) {
              return (
                <SideBarLink key={name} url={url} title={name}>
                  <Icon />
                  {isOpen && <span>{isConsultant ? `My ${name}` : name}</span>}
                </SideBarLink>
              );
            } else if (
              userPermissions.find((e) => e === 'VIEW_FACILITY') &&
              url === '/facilities'
            ) {
              return (
                <SideBarLink key={name} url={url} title={name}>
                  <Icon />
                  {isOpen && <span>{isConsultant ? `My ${name}` : name}</span>}
                </SideBarLink>
              );
            } else if (
              userPermissions.find((e) => e === 'VIEW_USERS') &&
              url === '/users' &&
              isEDM
            ) {
              return (
                <SideBarLink key={name} url={url} title={name}>
                  <Icon />
                  {isOpen && <span>{name}</span>}
                </SideBarLink>
              );
            } else if (url === '/settings') {
              return (
                <SideBarLink key={name} url={url} title={name}>
                  <Icon />
                  {isOpen && <span>{name}</span>}
                </SideBarLink>
              );
            } else {
              return null;
            }
          }
        }
      });
    }
  };

  return (
    <div className={`${className} SideNav`}>
      <NavBrand onClick={toggleSideNav} isOpen={isOpen} />
      <div className="SideNav-Content">{displayNavContent()}</div>
    </div>
  );
};

const generateWidth = (isOpen) => {
  if (isOpen) return `width: var(--sidebar-main-w) !important;`;
  else return `width: var(--sidebar-min-w) !important; `;
};

export default styled(SideNav)`
  flex-direction: columns;
  ${(props) => generateWidth(props.isOpen)};
  background: var(--sidebar-bg);
  min-height: 100vh;
  position: fixed;
  z-index: 100;
  font-family: var(--font-family);
  .SideNav-Content {
    margin-top: 3rem;
    overflow: hidden;
  }
  @media screen and (max-width: 769px) {
    width: var(--sidebar-min-w) !important;
    position: absolute;
    z-index: 100;
  }
`;
