import React, { useEffect, useState } from 'react';
import '../../../../components/screens/facilities/index.css';
import {
  approvePayment,
  getInvoices,
  initializePayment,
  resendInvoice,
} from '../../../../helpers/api';
import { formatDate, properCase } from '../../../../helpers/utils';
import { Button, Divider, Modal, Popconfirm, Table, Tag, Typography } from 'antd';
import FacilityInvoicePaymentOptions from './FacilityInvoicePaymentOptions';
import '../index.css';
import CloseIcon from '../../../../components/common/CloseIcon';
import LoadingContent from '../../../../components/common/LoadingContent';
import { openNotification } from '../../../../helpers/notification';
import EmptyContent from '../../../common/EmptyContent';
import Dropdown from '../../../../components/common/Dropdown';
import Menu from '../../../common/Menu';
import { CarryOutOutlined, MoreOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import InvoicePaymentApprovalForm from './InvoicePaymentApprovalForm';

let overflowMenu, moreOutlinedIcon;

export default function FacilityInvoiceList({ record }) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [reportId, setReportId] = useState('');
  const [data, setData] = useState([]);
  const [statusCode, setStatusCode] = useState('');
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [showPaymentOptionsModal, setShowPaymentOptionsModal] = useState(false);
  const [showApprovalForm, setShowApprovalForm] = useState(false);
  const { Text } = Typography;
  const [row, setRow] = useState();
  console.log(row);

  useEffect(() => {
    fetchInvoices();
  }, []);

  const fetchInvoices = () => {
    setLoading(true);
    getInvoices(search, record.facilityId, status, reportId, 1, 100)
      .then((res) => {
        setLoading(false);
        setData(res.result.data);
        setStatusCode(res.result.status.code);
      })
      .catch((err) => {
        setLoading(false);
        return openNotification({
          type: 'error',
          title: '',
          message: err.result.status.desc,
        });
      });
  };

  const handleSelectPaymentOption = (data) => {
    setSelectedInvoice(data);
    setShowPaymentOptionsModal(!showPaymentOptionsModal);
  };

  const renderPaymentOptionsModalHeader = () => {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'space-between' }}
        className="facility-report__header"
      >
        <div style={{ alignSelf: 'start' }}>
          <div className="report-title">
            <Text type="strong">Payment Options</Text>
          </div>
        </div>
        <div style={{ alignSelf: 'end', textAlign: 'right' }}>
          <CloseIcon
            className="close-icon report-viewing"
            onClick={() => setShowPaymentOptionsModal(!showPaymentOptionsModal)}
            style={{
              marginBottom: '1rem',
              marginTop: '0.8rem',
            }}
          />
        </div>
      </div>
    );
  };

  const handleApprovePayment = () => {
    setLoading(true);
    const payload = {
      invoiceId: row.invoiceId,
      comment: 'Approved',
    };
    approvePayment(payload)
      .then((response) => {
        setLoading(false);
        fetchInvoices();
      })
      .catch((error) => {
        setLoading(false);
        return openNotification({
          type: 'error',
          title: '',
          message: 'Something went wrong. Please try again',
        });
      });
  };

  const handleResendInvoice = () => {
    setLoading(true);
    resendInvoice(row.reportId)
      .then((res) => {
        setLoading(false);
        return openNotification({
          type: 'success',
          title: '',
          message: 'Invoice has been resent',
        });
        //fetchInvoices();
      })
      .catch((err) => {
        setLoading(false);
        return openNotification({
          type: 'error',
          title: '',
          message: 'Something went wrong. Please try again',
        });
      });
  };

  const handleViewInvoice = () => {
    history.push(`../../${row.fileUrl}`);
  };

  const columns = [
    {
      title: 'Invoice Date',
      dataIndex: 'entryDate',
      key: 'entryDate',
      render: (entryDate) => (
        <span>{entryDate ? formatDate(new Date(entryDate), 'LLL do, yyyy') : '00:00'}</span>
      ),
    },
    {
      title: 'Invoice Title',
      dataIndex: 'reportTitle',
      key: 'reportTitle',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => (
        <div>
          &#8358;
          {parseFloat(amount.toString())
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
        </div>
      ),
    },
    {
      title: 'Invoice No.',
      dataIndex: 'invoiceNo',
      key: 'invoiceNo',
    },
    {
      title: 'Status',
      dataIndex: 'invoiceStatus',
      key: 'invoiceStatus',
      render: (_, data) => {
        let color = data.invoiceStatus === 'OPEN' ? 'volcano' : 'green';
        return (
          <Tag color={color} style={{ width: '70px', textAlign: 'center' }}>
            {properCase(data.invoiceStatus)}
          </Tag>
        );
      },
    },
    // {
    //     title: '',
    //     key: 'invoiceActions',
    //     render: (_, data) => {
    //         if (data.invoiceStatus === 'OPEN') {
    //             return (
    //                 <Button
    //                     type="link"
    //                     block
    //                     size={10}
    //                     onClick={() => handleSelectPaymentOption(data)}
    //                 >
    //                     Pay
    //                 </Button>
    //             )
    //         }
    //     }
    // }
    {
      title: '',
      key: 'invoiceId',
      dataIndex: 'invoiceId',
      render: (invoiceId, record) => {
        return (
          <Dropdown overlay={overflowMenu} trigger={['click']}>
            <a
              className="ant-dropdown-link"
              href="/#"
              onClick={(e) => e.preventDefault() && actionInProgress()}
            >
              {moreOutlinedIcon(record)}
            </a>
          </Dropdown>
        );
      },
    },
  ];

  moreOutlinedIcon = (record) => (
    <MoreOutlined
      className="icon-more-outline"
      onClick={() => setRow(record)}
      // onMouseEnter={() => setRow(record)}
    />
  );

  overflowMenu = (
    <Menu className="facility-overflow__menu" style={{ width: '170px' }}>
      {row && (
        <Menu.Item>
          <a href={row.fileUrl} target="_blank">
            <span>View Invoice</span>
          </a>
        </Menu.Item>
      )}

      {row && row.invoiceStatus === 'OPEN' && (
        <Menu.Item onClick={handleResendInvoice}>
          <span>Resend Invoice</span>
        </Menu.Item>
      )}

      {row && row.invoiceStatus === 'OPEN' && (
        <Menu.Item onClick={() => setShowApprovalForm(true)}>
          <span>Approve Payment</span>
        </Menu.Item>
      )}

      {/* {row && row.invoiceStatus === 'OPEN' && (
        // <Menu.Item onClick={() => setShowApprovalForm(true)}>
        //   <span>Approve Payment</span>
        // </Menu.Item>
        <Popconfirm
          title={<p>Are you sure you want to approve this payment?</p>}
          onConfirm={handleApprovePayment}
          okText="Yes"
          cancelText="No"
        >
          <Menu.Item>
            <span>Approve Payment</span>
          </Menu.Item>
        </Popconfirm>
      )} */}
    </Menu>
  );

  const handleDisplay = () => {
    if (loading) {
      return <LoadingContent pageLoading={loading} />;
    }
    if (!loading && data.length < 1 && statusCode === '00') {
      return handleEmptyContent();
    }
    if (!loading && data.length > 0 && statusCode === '00') {
      return contentDisplay();
    }
  };

  const handleEmptyContent = () => {
    return (
      <EmptyContent className="no-content">
        <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
          <div>
            {/* <h1 className="no-content-header">Your Invoices</h1> */}
            <p className="no-content-text">We could not find any invoice for you.</p>
            <div className="buttons">
              <Button type="secondary">TRY AGAIN</Button>
            </div>
          </div>
        </div>
      </EmptyContent>
    );
  };

  const contentDisplay = () => {
    return <Table columns={columns} dataSource={data} />;
  };

  return (
    <div>
      {handleDisplay()}
      {showApprovalForm && (
        <InvoicePaymentApprovalForm
          showApprovalForm={showApprovalForm}
          toggleApprovalForm={() => setShowApprovalForm(false)}
          facilityName={record?.facilityName}
          invoiceId={row?.invoiceId}
        />
      )}

      <Modal
        visible={showPaymentOptionsModal}
        title={renderPaymentOptionsModalHeader()}
        className="facility-report__standard"
        footer={null}
      >
        <FacilityInvoicePaymentOptions invoiceRecord={selectedInvoice} />
      </Modal>
    </div>
  );
}
