import { Tabs } from 'antd';
import PageContent from '../../common/PageContent';
import FacilityQuarterlyReport from './FacilityQuarterlyReport';
import FacilityAnalyticsReport from './FacilityAnalytics';
//import './style.css';

export default function ReportingScreen() {
  const onChange = (key) => {
    console.log(key);
  };

  const items = [
    {
      key: '1',
      label: 'Quarterly Report',
      children: <FacilityQuarterlyReport />,
      //   children: <FacilityComparativeAnalytics />,
    },
    {
      key: '2',
      label: 'Analytics Report',
      children: <FacilityAnalyticsReport />,
      //   children: <FacilityComparativeAnalytics />,
    },
  ];
  return (
    <PageContent>
      <div style={{ padding: '1rem 2rem' }}>
        <br />
        <Tabs tabPosition="left" defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
    </PageContent>
  );
}
