import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import SearchIcon from '../../../assets/img/search.svg';
import UserIcon from '../../../assets/img/user.svg';
import RolesIcon from '../../../assets/img/roles.svg';
import LockIcon from '../../../assets/img/white_lock.svg';
import ConsultantSettings from './ConsultantSettings';

const SettingsContent = ({ className, userCategory, userPermission }) => {
  const userCat = userCategory.trim().toLowerCase();

  const userPerm =
    userPermission && userPermission.length > 0
      ? userPermission.map((mp) => mp.trim().toLowerCase())
      : [];

  const canViewLookup = userPerm.includes("manage_system_configurations");
  const canViewSystemSettings = userCat === 'edm';
  const canViewTemplate = userCat === 'edm';
  const canMakeTemplate = userCat === 'edm';
  const canViewRoles = userPerm.includes("manage_system_configurations");
  const canMakeRoles = userCat === 'edm';

  const isEDM = userCat && userCat.toLowerCase().trim() === 'edm';
  const isConsultant = userCat && userCat.toLowerCase().trim() === 'consultant';
  const isRegulator = userCat && userCat.toLowerCase().trim() === 'regulator';

  return (
    <div className={className}>
      <Row>
        <Col span={24} offset={1}>
          <Row justify="space-between">
            <Col span={22}>{isConsultant && <ConsultantSettings />}</Col>

            {canViewSystemSettings && (
              <Col span={12}>
                <h3 className="sub-heading bold">System Settings</h3>
                <Row>
                  {canViewLookup && (
                    <Col lg={7}>
                      <div className="settings-card blue">
                        <Link to="/settings/lookups">
                          <img src={SearchIcon} alt="" />
                          <p className="mt-15 small-text">Lookup Management</p>
                        </Link>
                      </div>
                    </Col>
                  )}
                  {canViewTemplate && (
                    <Col lg={8}>
                      <div className="settings-card light">
                        <Link to="/settings">
                          <img src={SearchIcon} alt="" />
                          <p className="mt-15 small-text">Template Management</p>
                        </Link>
                      </div>
                    </Col>
                  )}
                  <Col lg={8}>
                    <div className="settings-card light">
                      <Link to="/settings/configurations">
                        <img src={LockIcon} alt="" />
                        <p className="mt-15 small-text">Configuration Settings</p>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
            {/* {!isEDM && (
              <Col lg={12}>
                <h3 className="sub-heading bold">System Settings</h3>
                <Row>
                  <Col lg={8}>
                    <div className="settings-card light">
                      <Link to="/settings/configurations">
                        <img src={LockIcon} alt="" />
                        <p className="mt-15 small-text">Configuration Settings</p>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Col>
            )} */}
            {!isConsultant && (
              <Col span={11}>
                <h3 className="subheading bold">Account Settings</h3>
                <Row>
                  <Col lg={9}>
                    <div className="settings-card purple">
                      <Link to="/settings/profile">
                        <img src={UserIcon} alt="" />
                        <p className="mt-15 small-text">My Personal Information</p>
                      </Link>
                    </div>
                  </Col>
                  {canViewRoles && (
                    <Col lg={8}>
                      <div className="settings-card red">
                        <Link to="/settings/roles">
                          <img src={RolesIcon} alt="" />
                          <p className="mt-15 small-text">Roles & Permissions</p>
                        </Link>
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default styled(SettingsContent)`
  padding-top: 3rem;
  font-family: var(--font-family);
  .settings-card {
    // width: 173px;
    height: 140px;
    margin-right: 1rem;
    margin-top: 30px;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    a {
      display: block;
      width: 100%;
      padding: 1rem 8px;
    }
    img {
      width: 50px;
      height: 50px;
    }
    p {
      // font-family: Averta;
      line-height: 1.5;
      overflow: hidden;
    }
  }

  .settings-card.blue {
    background: #007d79;
  }
  .settings-card.light {
    background: #0072c3;
  }
  .settings-card.purple {
    background: #8a3ffc;
  }
  .settings-card.red {
    background: #fb4b53;
  }
`;
