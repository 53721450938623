import React, { useState, useEffect } from 'react';
import { Row, Col, Steps } from 'antd';
import PageContainer from '../../components/common/PageContainer';
import BreadCrumb from '../../components/common/BreadCrumb';
import LoadingContent from '../../components/common/LoadingContent';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import {
  updateFacility,
  getRegions,
  getStates,
  getLgas,
  getSectors,
  getFacilityCategory,
} from '../../helpers/api';
import { openNotification } from '../../helpers/notification';
import {
  properCase,
  convertDMS,
  // copyTextToClipboard,
} from '../../helpers/utils';

import '../CreateNewFacility/index.css';
import '../../scrollfix.css';

const { Step } = Steps;

const extractFormat = (data, fmt) => {
  let nData = [];
  data.map((dt) => {
    const newObj = {
      ...dt,
      id: dt[`${fmt}Id`],
      text: dt[`${fmt}Name`],
    };
    nData.push(newObj);
  });
  return nData;
};

export default (props) => {
  let sptPoint = [];
  if (props.facility.samplePoints && props.facility.samplePoints.length > 0) {
    props.facility.samplePoints.map((spt) => {
      const exSamplePt = {
        key: spt.samplePointId,
        id: spt.samplePointId,
        icon: spt.icon || '',
        // icon: spt.icon.iconSrc || '',
        checkCount: spt.checkCount || 3,
        samplePointName: properCase(spt.samplePointName),
        samplePointType: spt.measurementId,
        actualCoordinates:
          spt.coordinates && spt.coordinates.latitude && spt.coordinates.longitude
            ? `${spt.coordinates.latitude.trim()} - ${spt.coordinates.longitude.trim()}`
            : '',
        coordinates:
          spt.coordinates && spt.coordinates.latitude && spt.coordinates.longitude
            ? convertDMS(spt.coordinates.latitude.trim(), spt.coordinates.longitude.trim())
            : '',
        measureNoise: spt.measureNoise,
      };
      sptPoint.push(exSamplePt);
    });
  }

  const initialState = {
    current: 0,
    stepOneState: {
      facilityName: props.facility.facilityName,
      address: props.facility.address,
      latitude: props.facility.coordinates.latitude,
      longitude: props.facility.coordinates.longitude,
      products: extractFormat(props.facility.facilityProduct, 'product'),
      rawMaterials: extractFormat(props.facility.facilityRawMaterial, 'rawMaterial'),
      facilityWastes: extractFormat(props.facility.facilityWaste, 'waste'),
      sector: props.facility.sectorId,
      region: props.facility.zoneId,
      lga: props.facility.lgaId,
      state: props.facility.stateId,
      facilityCategoryId: props.facility.facilityCategoryId,
      sectorLists: '',
      regionLists: '',
      statesLists: '',
      lgaLists: '',
      facilityCategoryLists: '',
      sectorName: props.facility.sectorName,
      regionName: props.facility.zoneName,
      stateName: props.facility.stateName,
      lgaName: props.facility.lgaName,
      registrationNo: props.facility.registrationNo,
      //facilityCategoryName:props.facility

      // sector: { id: props.facility.sectorId, text: props.facility.sectorName },
      // region: { id: props.facility.zoneId, text: props.facility.zoneName },
      // lga: { id: props.facility.lgaId, text: props.facility.lgaName },
      // state: { id: props.facility.stateId, text: props.facility.stateName },
      facilityStatus: props.facility.status.toLowerCase() === 'active' ? 'active' : 'inactive',
      eiaCertStatus:
        props.facility.facilityEia && props.facility.facilityEia.toLowerCase() === 'yes'
          ? 'active'
          : 'inactive',
    },
    eiaCert: props.facility.eiaCert,
    stepTwoState: {
      samplePoints: sptPoint,
    },
    stepThreeState: {
      consultants: [],
      personnels: [],
    },
  };

  if (props.facility.facilityConsultant && props.facility.facilityConsultant.length > 0) {
    props.facility.facilityConsultant.forEach((ftC, i) =>
      initialState['stepThreeState']['consultants'].push({
        key: i,
        id: props.facility.facilityConsultant[i].userId
          ? props.facility.facilityConsultant[i].userId
          : '',
        userId: props.facility.facilityConsultant[i].userId
          ? props.facility.facilityConsultant[i].userId
          : '',
        firstname: props.facility.facilityConsultant[i].firstName
          ? props.facility.facilityConsultant[i].firstName
          : '',
        lastname: props.facility.facilityConsultant[i].lastName
          ? props.facility.facilityConsultant[i].lastName
          : '',
        phone: props.facility.facilityConsultant[i].phone
          ? props.facility.facilityConsultant[i].phone
          : '',
        email: props.facility.facilityConsultant[i].email
          ? props.facility.facilityConsultant[i].email
          : '',
        userCategory: props.facility.facilityConsultant[i].userCategory
          ? props.facility.facilityConsultant[i].userCategory
          : '',
        status: props.facility.facilityConsultant[i].status
          ? props.facility.facilityConsultant[i].status
          : '',
        facilityName: props.facility.facilityName ? props.facility.facilityName : '',
        consultantFullName: props.facility.facilityConsultant[i].firstName
          ? `${props.facility.facilityConsultant[i].firstName} ${props.facility.facilityConsultant[i].lastName}`
          : '',
        name: props.facility.facilityConsultant[i].firstName
          ? `${props.facility.facilityConsultant[i].firstName} ${props.facility.facilityConsultant[i].lastName}`
          : '',
        text: props.facility.facilityConsultant[i].firstName
          ? `${props.facility.facilityConsultant[i].firstName} ${props.facility.facilityConsultant[i].lastName}`
          : '',
      })
    );
  }

  if (props.facility.facilityPersonel && props.facility.facilityPersonel.length > 0) {
    props.facility.facilityPersonel.map((psml, index) =>
      initialState['stepThreeState']['personnels'].push({ ...psml, key: index })
    );
  }

  const [state, setState] = useState(initialState);
  const [formStatus, setFormStatus] = useState(null);
  const [mounted, setMounted] = useState(null);
  // const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const getSectorsList = () => {
    getSectors('/LookUp/Sector').then(({ result }) => {
      const sLists = [];
      result.data &&
        result.data.length > 0 &&
        result.data.forEach((res) =>
          sLists.push({
            sectorId: res.sectorId,
            name: res.sectorName,
            id: res.sectorId,
            text: res.sectorName,
          })
        );
      const currSt = { ...state };
      currSt['stepOneState']['sectorLists'] = sLists;
      if (!sLists || sLists.length === 0) return setIsError(true);
      setState(currSt);
    });
  };

  function getRegionsLists() {
    getRegions('/LookUp/Zone').then(({ result }) => {
      const rLists = [];
      result.data &&
        result.data.length > 0 &&
        result.data.forEach((res) =>
          rLists.push({
            zoneId: res.zoneId,
            name: res.zoneName,
            zoneName: res.zoneName,
            id: res.zoneId,
            text: res.zoneName,
          })
        );
      const currSt = { ...state };
      currSt['stepOneState']['regionLists'] = rLists;
      if (!rLists || rLists.length === 0) return setIsError(true);
      setState(currSt);
    });
  }

  const getStatesLists = (forThisZone, which) => {
    let withWhat = 'zoneName';
    if (which) withWhat = which;
    getStates('/LookUp/State').then(({ result }) => {
      const sLists = [];
      result.data &&
        result.data.length > 0 &&
        result.data.forEach(
          (res) =>
            res[withWhat] === forThisZone &&
            sLists.push({
              stateId: res.stateId,
              stateCode: res.stateCode,
              name: res.stateName,
              region: res.zoneName,
              zoneName: res.zoneName,
              lga: res.lga,
              id: res.stateId,
              text: res.stateName,
            })
        );
      const currSt = { ...state };
      currSt['stepOneState']['statesLists'] = sLists;
      if (!sLists || sLists.length === 0) return setIsError(true);
      setState(currSt);
    });
  };

  const getLgasLists = (forThisState, which) => {
    let withWhat = 'stateName';
    if (which) withWhat = which;

    getLgas('/LookUp/Lga').then(({ result }) => {
      const lgLists = [];
      result.data &&
        result.data.length > 0 &&
        result.data.forEach(
          (res) =>
            res[withWhat].trim().toLowerCase() === forThisState.trim().toLowerCase() &&
            lgLists.push({
              lgaId: res.lgaId,
              lgaCode: res.lgaCode,
              name: res.lgaName,
              stateName: res.stateName,
              lga: res.lgaName,
              id: res.lgaId,
              text: res.lgaName,
            })
        );
      const currSt = { ...state };
      currSt['stepOneState']['lgaLists'] = lgLists;
      if (!lgLists || (lgLists && lgLists.length === 0)) {
        setIsError(true);
        // return setIsError(true);
      } else setState(currSt);
    });
  };

  const getFacilityCategoryList = () => {
    const facilityCatLists = [];
    getFacilityCategory().then((response) => {
      if (response.result.data) {
        response.result.data.forEach((res) => {
          facilityCatLists.push({
            categoryId: res.categoryId,
            categoryName: res.categoryName,
            id: res.categoryId,
            text: res.categoryName,
          });
        });
      }

      const currSt = { ...state };
      currSt['stepOneState']['facilityCategoryLists'] = facilityCatLists;
      if (!facilityCatLists || (facilityCatLists && facilityCatLists.length === 0)) {
        setIsError(true);
        // return setIsError(true);
      } else setState(currSt);
    });
  };

  // const getFacilityCategoryList = () => {
  //   getFacilityCategory()
  //     .then((res) => {
  //       setFacilityCategoryList(res.result.data);
  //     })
  //     .catch((err) => {
  //       return openNotification({
  //         type: 'error',
  //         title: 'Failed to get facility categories',
  //         message: `We encountered an error while trying to get list of states`,
  //       });
  //     });
  // };

  useEffect(() => {
    try {
      !mounted && setMounted(true);
      if (mounted) props.closeEditing('false', 'startediting');
      setState(initialState);
      getSectorsList();
      getRegionsLists();
      getStatesLists(props.facility.zoneName, 'zoneName');
      getLgasLists(props.facility.stateName, 'stateName');
      getFacilityCategoryList();
      return () => {
        props.closeEditing('', 'closeediting');
        setMounted(null);
      };
    } catch (err) {
      setIsError(true);
      props.closeEditing(err, 'error');
    }
  }, []);

  const next = () => {
    const current = state.current + 1;
    const newState = { ...state, current };
    setState(newState);
  };

  const prev = () => {
    const current = state.current > 0 ? state.current - 1 : 0;
    const newState = { ...state, current };
    setState(newState);
  };

  const getPersonnel = (data) => {
    const personl = [];
    if (data && data.length > 0) {
      data.forEach((dt) =>
        personl.push({
          fullName: dt.fullName,
          email: dt.email,
          phone: dt.phone,
          designation: dt.designation,
        })
      );
    }
    return personl;
  };

  const getConsultant = (data) => {
    const consulta = [];
    if (data && data.length > 0) {
      data.forEach((dt) => dt.userId && consulta.push(dt.userId));
    }
    return consulta;
  };

  const getSamplePoints = (data) => {
    const sampPts = [];
    if (data.length > 0) {
      data.forEach((dt, index) =>
        sampPts.push({
          icon: dt.icon.iconSrc ? dt.icon.iconSrc : dt.icon ? dt.icon : null,
          samplePointId: dt.samplePointId
            ? dt.samplePointId
            : dt.id
            ? String(dt.id)
            : String(index),
          samplePointName: dt.samplePointName,
          // facilityId: props.facility.facilityId,
          coordinates: !dt.actualCoordinates
            ? null
            : {
                latitude: String(dt.actualCoordinates.split('-')[0]).trim(),
                longitude: String(dt.actualCoordinates.split('-')[1]).trim(),
              },
          checkCount: parseInt(dt.checkCount),
          measurementId: dt.samplePointType,
          measureNoise: dt.measureNoise,
          equipmentId: dt.equipmentId,
        })
      );
    }
    return sampPts;
  };

  const getData = (data, check, cb) => {
    const dataWithId = cb(check);
    const products = data.filter((dt) => !!dt[check] === dataWithId);
    let returnProducts = [];
    if (products.length > 0) {
      if (dataWithId) {
        products.forEach((dt) => returnProducts.push(dt[check]));
      } else {
        products.forEach((dt) => returnProducts.push(dt.text));
      }
    }
    return returnProducts;
  };

  const submitForm = (data) => {
    setFormStatus({ type: 'loading', msg: 'sending data' });
    try {
      const currentState = { ...state };
      currentState['stepThreeState'] = data;

      const { stepOneState: s1, stepTwoState: s2, stepThreeState: s3 } = currentState;

      const payload = {
        // facilityId: props.facility.facilityId,
        facilityName: s1.facilityName,
        stateId: s1.state ? s1.state : null,
        address: s1.address ? s1.address : null,
        status: s1.facilityStatus === 'active' ? 1 : 2,
        facilityEia: s1.eiaCertStatus === 'active' ? 1 : 2,
        eiaCert: s1.eiaCertStatus === 'active' ? props.facility.eiaCert : null,
        facilityPersonel: getPersonnel(s3.personnels),
        coordinates: {
          latitude: String(s1.latitude),
          longitude: String(s1.longitude),
        },
        remarks: s1.stateId ? s1.stateId : null,
        sectorId: s1.sector,
        zoneId: s1.region,
        lgaId: s1.lga,
        consultantId: getConsultant(s3.consultants),
        productId: getData(s1.products, 'productId', (hasProductId) => true),
        productName: getData(s1.products, 'productId', (hasProductId) => false),
        rawMaterialId: getData(s1.rawMaterials, 'rawMaterialId', (hasRawMaterialId) => true),
        rawMaterialName: getData(s1.rawMaterials, 'rawMaterialId', (hasRawMaterialId) => false),
        wasteId: getData(s1.facilityWastes, 'wasteId', (hasWasteId) => true),
        wasteName: getData(s1.facilityWastes, 'wasteId', (hasWasteId) => false),
        samplePoints: getSamplePoints(s2.samplePoints),
        registrationNo: s1.registrationNo,
        facilityCategoryId: s1.facilityCategoryId,
      };

      console.log(payload);
      // copyTextToClipboard(JSON.stringify(payload));

      updateFacility(props.facility.facilityId, payload)
        .then((res) => {
          if (res.result.status.code === '00') {
            setFormStatus({ type: 'success', msg: 'Sent' });
          } else {
            openNotification({
              title: `${res.result.status.desc}`,
              type: 'error',
              duration: 10,
            });
            setFormStatus({ type: 'error', msg: res.result.status.desc });
          }
        })
        .catch((error) => {
          let errorMsg = 'Something went wrong!';
          if (error && error.result && error.result.status && error.result.status.desc) {
            errorMsg = error.result.status.desc;
          }
          // console.log('problem in update facility from server', error);
          setFormStatus({ type: 'error', msg: errorMsg });
          openNotification({
            title: `${errorMsg}`,
            type: 'error',
            duration: 10,
          });
        });
    } catch (err) {
      // console.log('problem in update facility from client', err);
      setFormStatus({ type: 'error', msg: err });
    }
  };

  const updateMasterForm = (newState, which, whereTo) => {
    const currentState = { ...state };
    if (which) {
      currentState[which] = newState;
      if ((whereTo || whereTo === 0) && whereTo !== 3) currentState['current'] = whereTo;
      if (whereTo && whereTo === 3) return submitForm(newState);
    } else {
      if (current === 0) {
        currentState['stepOneState'] = newState;
        currentState['current'] = 1;
      }
      if (current === 1) {
        currentState['stepTwoState'] = newState;
        currentState['current'] = 2;
      }
      if (current === 2) currentState['stepThreeState'] = newState;
    }
    setState(currentState);
  };

  const steps = [
    {
      title: 'Facility Details',
      content() {
        return (
          <StepOne
            state={state}
            updateMasterForm={updateMasterForm}
            next={next}
            mounted={mounted}
          />
        );
      },
    },
    {
      title: 'Sample Points',
      content() {
        return (
          <StepTwo state={state} updateMasterForm={updateMasterForm} next={next} prev={prev} />
        );
      },
    },
    {
      title: 'Consultant',
      content() {
        return (
          <StepThree
            state={state}
            initialState={initialState}
            updateMasterForm={updateMasterForm}
            cancelEditing={() => props.closeEditing('', 'closeediting')}
            next={next}
            prev={prev}
            formStatus={formStatus}
            setFormStatus={setFormStatus}
          />
        );
      },
    },
  ];

  const { current } = state;

  if (isError) return props.closeEditing('Error loading facility details', 'error');

  if (
    mounted &&
    !isError &&
    (!state['stepOneState']['statesLists'] ||
      !state['stepOneState']['lgaLists'] ||
      !state['stepOneState']['sectorLists'])
  ) {
    return (
      <div className="spin-fullpage">
        <LoadingContent pageLoading={true} />
      </div>
    );
  }

  if (!mounted) return null;

  return (
    <PageContainer>
      <div style={{ marginBottom: '10px !important' }} className="fac-breadcrumb">
        <Row style={{ marginBottom: '10px !important' }}>
          <Col lg={{ span: 20 }} offset={1} style={{ marginBottom: '10px !important' }}>
            <BreadCrumb
              previousPath="/facilities"
              previousView="Facilities"
              currentView="update"
              clickHandler={props.closeEditing}
            />
          </Col>
        </Row>
      </div>

      <Row
        type="flex"
        justify="start"
        align="middle"
        className="text-left"
        style={{ marginTop: '-2rem' }}
      >
        <Col span={16} offset={4}>
          <div>
            <Row type="flex" justify="start" align="middle" className="text-left">
              <Col span={7}>
                <h4 className="small-heading px-0 subheading mr-10">{steps[current]['title']}</h4>
              </Col>
              <Col span={6}>
                <Steps current={current} className="small-heading">
                  {steps.map((item) => (
                    <Step key={item.title} title={''} />
                  ))}
                </Steps>
              </Col>
              <Col span={3} />
            </Row>
            {mounted && !isError && <div className="steps-content">{steps[current].content()}</div>}
          </div>
        </Col>
      </Row>
    </PageContainer>
  );
};
